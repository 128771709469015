import "./globals.scss";

import React from "react";
import ReactDOM from "react-dom";

import EntergyApp from "./EntergyApp";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<React.StrictMode>
		<EntergyApp />
	</React.StrictMode>,
	document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
